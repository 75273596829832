import AbstractComponent from './abstractComponent';

export default class landingMenu extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $(".tab-item").click(function handleTabClick(){
                const tabIndex = $(this).data("tab");
                $(".tab-item").removeClass("active");
                $(".tab-content").hide();
                $(`.tab-content[data-content="${tabIndex}"]`).show();
                $(this).addClass("active");
            });
        }
    }
}
