import AbstractComponent from './abstractComponent';
import 'slick-carousel';

export default class NavigableContainer extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            this.initTabs();
            this.initAccordion();
            this.initMobileSlider();
            $(window).on('resize', () => {
                this.initMobileSlider();
            });
        }
    }

    initMobileSlider() {
        const imageRow = $(this.selector).find('.image-row'); // Scope it to the instance's selector
        const isMobile = $(this.selector).find('.mobile').length > 0;

        if (isMobile && $(window).width() <= 991) {
            if (!imageRow.hasClass('slick-initialized')) {
                imageRow.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: true,
                    infinite: false,
                    adaptiveHeight: false,
                });
            }
        } else if (imageRow.hasClass('slick-initialized')) {
            imageRow.slick('unslick');
        }
    }

    static reinitializeSlider(imageRow) {
        if ($(imageRow).hasClass('slick-initialized')) {
            $(imageRow).slick('unslick');
        }

        $(imageRow).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            infinite: false,
            adaptiveHeight: false,
        });
    }

    /* eslint-disable class-methods-use-this */
    initTabs() {
        let isScrollingByClick = false;
    
        $(".tab-item").click((event) => { // Using arrow function here
            isScrollingByClick = true;
    
            $(".tab-item").removeClass("active");
            $(event.currentTarget).addClass("active");
    
            const targetId = $(event.currentTarget).attr("data-tab");
            const $targetContent = $(`#${targetId}`);
    
            if ($targetContent.length) {
                $(".tab-content").css("padding-top", "0");
                $targetContent.css("padding-top", "100px");
    
                $("html, body").animate(
                    {
                        scrollTop: $targetContent.offset().top
                    },
                    500,
                    () => { // Arrow function here as well
                        isScrollingByClick = false;
                    }
                );
            }
        });
    
        $(window).on("scroll", () => { // Arrow function here
            if (!isScrollingByClick) {
                $(".tab-content").css("padding-top", "0");
            }
    
            $(".tab-content").each((index, element) => { // Arrow function here
                const contentTop = $(element).offset().top;
                const contentHeight = $(element).outerHeight();
                const scrollPos = $(window).scrollTop();
                const windowHeight = $(window).height();
    
                if (scrollPos + windowHeight / 2 >= contentTop && scrollPos < contentTop + contentHeight) {
                    const activeTabId = $(element).attr("id");
                    $(".tab-item").removeClass("active");
                    $(`.tab-item[data-tab="${activeTabId}"]`).addClass("active");
                }
            });
        });
    }
    
    

    initAccordion() {
        const accordionItems = $(this.selector).find('.accordion-item');
        const accordionContents = $(this.selector).find('.accordion-content');

        if (accordionItems.length === 0 || accordionContents.length === 0) return;

        accordionItems.each((index, item) => {
            $(item).on('click', () => {
                const isActive = $(item).hasClass('active');

                // Reset all accordion items
                accordionItems.removeClass('active').find('.minus-icon').hide().siblings('.plus-icon').show();
                accordionContents.removeClass('active');

                if (!isActive) {
                    $(item).addClass('active').find('.minus-icon').show().siblings('.plus-icon').hide();
                    accordionContents.eq(index).addClass('active');

                    const activeImageRow = accordionContents.eq(index).find('.image-row');
                    if (activeImageRow.length > 0) {
                        NavigableContainer.reinitializeSlider(activeImageRow);
                    }
                }
            });
        });

        // Set first accordion as active
        accordionItems.first().addClass('active').find('.minus-icon').show().siblings('.plus-icon').hide();
        accordionContents.first().addClass('active');

        const initialImageRow = accordionContents.first().find('.image-row');
        if (initialImageRow.length > 0) {
            NavigableContainer.reinitializeSlider(initialImageRow);
        }
    }
}
