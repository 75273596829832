import AbstractComponent from './abstractComponent';
import 'jquery-ui/themes/base/autocomplete.css';
import 'jquery-ui/ui/widgets/autocomplete';

export default class PublicationsArchive extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);
        function updatePaginationButtons(currentPage, total) {
            $('.page-prev').toggleClass('disable', currentPage === 0);
            $('.page-next').toggleClass('disable', currentPage === total - 1);
        }
        const totalPage = $("#page-list").data('pagecount');
        function getPublicationList(page, path) {
            const search = $('#news-search').val();
            const type = $('#news-type').val();
            const limit = 6;
            const waitMessage = $('.newsSearch__wait');
            $.ajax({
                url: path,
                data: {
                    type,
                    search,
                    page,
                    limit,
                },
                dataType: 'json',
                beforeSend() {
                    waitMessage.show();
                },
                success(result) {
                    waitMessage.hide();
                    $('.newsArchiveArticles__list').html(result.html);
                    updatePaginationButtons(page, totalPage);
                },
            });
        }
        function renderPagination(pagination, page = 1) {
            const paginationContainer = document.getElementById('page-list');
            paginationContainer.innerHTML = pagination
                .map(item => 
                    typeof item === "number"
                        ? `<li class="page-number${ (item === page) ? ' active' : ''  }" data-next-page="${ item }">${item}</li>`
                        : `<li>${item}</li>`   
                )
                .join('');
        }
        function generatePagination(currentPage, totalPages) {
            const pagination = [];
            const maxVisiblePages = 5;
        
            if (totalPages <= maxVisiblePages) {
                for (let i = 1; i <= totalPages; i += 1) {
                    pagination.push(i);
                }
            } else {
                const start = Math.max(2, currentPage - 1);
                const end = Math.min(totalPages - 1, currentPage + 1);
        
                pagination.push(1); 
        
                if (start > 2) {
                    pagination.push('...'); 
                }
        
                for (let i = start; i <= end; i += 1) {
                    pagination.push(i);
                }
        
                if (end < totalPages - 1) {
                    pagination.push('...'); 
                }
        
                pagination.push(totalPages); 
            }
        
            return pagination;
        }
        if (this.exists) {
            
            const cache = {};
            jQuery('#news-search').autocomplete({
                minLength: 2,
                source(request, response) {
                    const { term } = request;
                    if (term in cache) {
                        response(cache[term]);
                        return;
                    }
                    request.type = jQuery('#news-type').val();
                    jQuery.getJSON(
                        '/api/v1/publications/autocomplete',
                        request,
                        (data) => {
                            cache[term] = data;
                            response(data);
                        }
                    );
                },
            });
        
            $(document).on('click', '.page-number', function () {
                const moreButton = $(this);
                $('.page-number').removeClass("active");
                moreButton.addClass("active");
                const nextPage = moreButton.data('next-page');
                const path = $("#page-list").data('path');
                getPublicationList(nextPage - 1, path);

                // Use setTimeout to wait for data load (simulate async behavior)
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }, 500);  // Adjust the delay as needed to ensure data has loaded
                
                const pagination = generatePagination(nextPage, totalPage);
                renderPagination(pagination, nextPage);
            });
        
            $('.page-next').click(() => {
                const moreButton = $('ul#page-list').find('li.active');
                const currentPageIndex = moreButton.data('next-page') - 1;
            
                if (currentPageIndex < totalPage - 1) {
                    $('.page-number').removeClass("active");
                    $(`.page-number:eq(${currentPageIndex + 1})`).addClass('active');
                    const path = $("#page-list").data('path');
            
                    getPublicationList(currentPageIndex + 1, path);
                    
                    // Use setTimeout to wait for data load (simulate async behavior)
                    setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }, 500);  // Adjust the delay as needed to ensure data has loaded
            
                    const pagination = generatePagination(currentPageIndex + 2, totalPage);
                    renderPagination(pagination, currentPageIndex + 2);
                }
            });
            
        
            $('.page-prev').click(() => {
                const moreButton = $('ul#page-list').find('li.active');
                const currentPageIndex = moreButton.data('next-page') - 1;
        
                if (currentPageIndex > 0) {
                    $('.page-number').removeClass("active");
                    $(`.page-number:eq(${currentPageIndex - 1})`).addClass('active');
                    const path = $("#page-list").data('path');
                    getPublicationList(currentPageIndex - 1, path);

                    // Use setTimeout to wait for data load (simulate async behavior)
                    setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }, 500);  // Adjust the delay as needed to ensure data has loaded

                    const pagination = generatePagination(currentPageIndex, totalPage);
                    renderPagination(pagination, currentPageIndex);
                }
            });
        
            updatePaginationButtons(0, totalPage);
            const pagination = generatePagination(1, totalPage);
            renderPagination(pagination);
        }
    }
}
